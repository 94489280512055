import { FC } from "react";
import { useIntl } from "react-intl";

import { makeStyles } from "tss-react/mui";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import HeadImage from "../assets/images/head.jpg";

interface MakeStylesProps {
  isSmOrDown: boolean;
}
const useStyles = makeStyles<MakeStylesProps>()((theme, { isSmOrDown }) => ({
  wrapper: {
    marginTop: theme.spacing(6),
  },
  headContainer: {
    height: isSmOrDown ? "280px" : "380px",
    backgroundImage: `url(${HeadImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    alignContent: "center",
  },
  headTextContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: isSmOrDown ? "90%" : "600px",
    marginLeft: isSmOrDown ? "calc(5% - 6px)" : undefined,
    marginRight: isSmOrDown ? "calc(5% - 6px)" : undefined,
    justifyContent: "center",
    gap: theme.spacing(2),
    cursor: "default",
    lineHeight: 0.7,
  },
  mainHeadText: {
    display: "block",
    fontSize: isSmOrDown ? theme.spacing(4) : theme.spacing(6),
    fontWeight: 700,
    textAlign: "center",
    lineHeight: 1.5,
    width: "100%",
    textTransform: "uppercase",
    backgroundColor: theme.palette.background.default,
    color: "#FFFFFF",
    padding: theme.spacing(0.75),
  },
  mainHeadTextSmall: {
    fontSize: isSmOrDown ? theme.spacing(3) : theme.spacing(4.5),
  },
  secondaryHeadText: {
    display: "block",
    fontSize: isSmOrDown ? theme.spacing(2) : theme.spacing(2.5),
    fontWeight: 300,
    textAlign: "center",
    lineHeight: 1.5,
    width: "100%",
    textTransform: "uppercase",
    backgroundColor: theme.palette.divider,
    color: "#FFFFFF",
    padding: theme.spacing(0.75),
  },
}));

export const Head: FC = () => {
  const intl = useIntl();
  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("md"));
  const { classes } = useStyles({ isSmOrDown });

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.headContainer}>
        <Container maxWidth="lg">
          <Box className={classes.headTextContainer}>
            <h1>
              <Typography className={classes.mainHeadText} component="span">
                &nbsp;
                {intl
                  .formatMessage({ id: "home.constituteYourSas" })
                  .toUpperCase()}
                &nbsp;
              </Typography>
              <br />
              <Typography
                className={classes.secondaryHeadText}
                component="span"
              >
                &nbsp;
                {intl
                  .formatMessage({ id: "home.inCordobaProvince" })
                  .toUpperCase()}
                &nbsp;
              </Typography>
              <br />
              <Typography
                className={`${classes.mainHeadText} ${classes.mainHeadTextSmall}`}
                component="span"
              >
                &nbsp;
                {intl
                  .formatMessage({ id: "home.operationalNationwide" })
                  .toUpperCase()}
                &nbsp;
              </Typography>
            </h1>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
