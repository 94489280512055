import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";

import messages from "./common/lang";
import Routes from "./common/routes/router";
import { light } from "./common/theme";
import "./App.css";
import { useEffect } from "react";

const DEFAULT_LOCALE = "es-AR";

const App = () => {
  const languageLocale = useSelector(
    (state: any) => state?.applicationReducer?.languageLocale
  );
  const locale = (languageLocale as keyof typeof messages) || DEFAULT_LOCALE;
  useEffect(() => {
    localStorage.setItem("languageLocale", locale);
  }, [locale]);

  return (
    <div>
      <IntlProvider
        messages={messages[locale]}
        locale={locale}
        defaultLocale={DEFAULT_LOCALE}
        onError={() => {}}
      >
        <ThemeProvider theme={light}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ThemeProvider>
      </IntlProvider>
    </div>
  );
};

export default App;
