import { FC } from "react";
import { useIntl } from "react-intl";

import { makeStyles } from "tss-react/mui";
import {
  Dialog as MuiDialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Link,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles()((theme) => ({
  titleText: {
    fontSize: theme.spacing(3),
    fontWeight: 700,
    color: "#000000",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "calc(100% - 52px)",
  },
  closeIcon: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  closeLink: {
    color: theme.palette.background.default,
    fontSize: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    cursor: "pointer",
  },
}));

const TOOLTIP_DELAY_MS = 500;

interface DialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  content: JSX.Element;
}

export const Dialog: FC<DialogProps> = ({ open, setOpen, title, content }) => {
  const { classes } = useStyles();
  const intl = useIntl();

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <MuiDialog
      open={open}
      onClose={handleCloseDialog}
      scroll="paper"
      maxWidth="lg"
    >
      <DialogTitle>
        <Box>
          <Tooltip
            title={title}
            enterDelay={TOOLTIP_DELAY_MS}
            enterNextDelay={TOOLTIP_DELAY_MS}
          >
            <Typography className={classes.titleText}>{title}</Typography>
          </Tooltip>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        <Link underline="none" onClick={handleCloseDialog}>
          <Typography className={classes.closeLink}>
            {intl.formatMessage({ id: "common.close" })}
          </Typography>
        </Link>
      </DialogActions>
    </MuiDialog>
  );
};
