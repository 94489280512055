const messages = {
  // Common
  "common.required": "Required",
  "common.iWantMySas": "I want my S.A.S.",
  "common.continue": "Continue",
  "common.upload": "Upload documents",
  "common.back": "Back",
  "common.email": "E-mail",
  "common.phone": "Phone",
  "common.firstName": "First name",
  "common.lastName": "Last name",
  "common.send": "Send",
  "common.option": "Option",
  "common.address": "Address",
  "common.city": "City",
  "common.department": "Departament",
  "common.province": "Province",
  "common.activity": "Activity",
  "common.view": "View",
  "common.cbu": "CBU",
  "common.shareHolder": "Shareholder",
  "common.cuit": "CUIT",
  "common.dni": "DNI",
  "common.maritalStatus": "Marital status",
  "common.profession": "Profession",
  "common.capitalContribution": "Capital contribution",
  "common.mustOnlyContainNumbers": "Must only contain numbers",
  "common.administrator": "Administrator",
  "common.mustBeValidDate": "Must be a valid date",
  "common.backToHomePage": "Back to home page",
  "common.autoFill": "Autofill",
  "common.language": "Language",
  "common.spanish": "Spanish",
  "common.english": "English",
  "common.close": "Close",
  "common.typeNewValues": "Type new values. Press Enter to confirm",
  "common.noOptions": "No options",
  "common.otherActivities": "Other activities",
  "common.isRegistredTrademark": "Please indicate if the option corresponds to a registered trademark",
  "common.isForeign": "Indicate if you do not have an address in Córdoba. If you are outside the city, an additional management fee will apply.",
  // Home
  "home.sasConstitution":
    "Constitution of a Sociedad por Acciones Simplificada (S.A.S.)",
  "home.sasJurisdiction": "In Córdoba Province Jurisdiction",
  "home.whyCordoba": "Why in the Province of Córdoba?",
  "home.whyCordobaAnswer1":
    "Unlike other jurisdictions, the Dirección General de Inspección de Personas Jurídicas of the Province of Córdoba has developed and allowed the incorporation of companies with great speed and ease, allowing the obtaining of the CUIT and its corresponding Registration Certificate within a timeframe ranging from two (2) to five (5) business days from the submission of the procedure. The entire process can be done online without the need to visit any public office.",
  "home.whyCordobaAnswer2":
    "For more information, we suggest you read the Benefits and Frequently Asked Questions sections.",
  "home.whatIsSas": "What is a S.A.S.?",
  "home.whatIsSasDescription":
    "It is a corporate type established by the Entrepreneurial Capital Support Law, whose main objective is to promote entrepreneurial activity and capital generation in the country, as well as its international expansion.",
  "home.constituteYourSas": "Constitute your S.A.S.",
  "home.inCordobaProvince": "In Córdoba Province",
  "home.operationalNationwide": "Operational nationwide",

  // Benefits
  "benefits.benefits": "Benefits",
  "benefits.whatAreTheBenefits": "What are the benefits?",
  "benefits.fast": "Fast",
  "benefits.fast.bulletPoint1":
    "Since the registration has been requested, your S.A.S. will not take more than 5 business days to obtain the Tax Identification Number (CUIT), registration, and be fully operational.",
  "benefits.fast.bulletPoint2":
    "The obtainment of the Tax Identification Number (CUIT) is automatic.",
  "benefits.easy.bulletPoint3":
    "A pre-approved model bylaws are used to avoid any observations from the supervisory body.",
  "benefits.easy": "Easy",
  "benefits.easy.bulletPoint1":
    "The jurisdiction of the Province of Córdoba is one of the few where an S.A.S. can be established without restrictions and in a short period of time.",
  "benefits.easy.bulletPoint2":
    "Once the required data form is completed, we will be ready to initiate the process.",
  "benefits.economical": "Economical",
  "benefits.economical.bulletPoint1":
    "Its costs are notably lower compared to other types of companies such as Public Limited Companies (S.A.) and Limited Liability Companies (S.R.L.).",
  "benefits.economical.bulletPoint2":
    "It has a minimum capital requirement of two minimum vital and mobile salaries.",
  "benefits.digital": "Digital",
  "benefits.digital.bulletPoint1":
    "All the required documentation can be signed digitally.",
  "benefits.digital.bulletPoint2":
    "You can keep the corporate and accounting records in digital format.",

  // Procedure
  "procedure.procedure": "Procedure",
  "procedure.whatIsTheProcedureLike": "What is the procedure like?",
  "procedure.procedure1.title": "Name Reservation",
  "procedure.procedure1.text":
    "Please inform us of the name you would like your S.A.S. to have, and we will request a name reservation to validate its availability.",
  "procedure.procedure2.title": "Data Submission",
  "procedure.procedure2.text":
    "If your chosen name is available, we will ask you to provide a series of data through a simple form to proceed with the incorporation of your S.A.S.",
  "procedure.procedure3.title": "Capital Integration",
  "procedure.procedure3.text":
    "You will need to deposit into an account specifically opened for your Company an amount equivalent to 25% of the share capital.",
  "procedure.procedure4.title": "Review of Articles of Incorporation",
  "procedure.procedure4.text":
    "Once we have all the required data and the transfer of the required share capital has been credited to the account, we will send you a Draft of the Articles of Incorporation and Bylaws for you to review and ensure that all the information stated there is correct.",
  "procedure.procedure5.title": "Signing and Certification of Documents",
  "procedure.procedure5.text":
    "We will send you the final versions of the constitutive documents of your S.A.S., as well as any additional documentation that may be required by the Public Registry, indicating the precise modalities and types of signatures required.",
  "procedure.procedure6.title": "Submission of Registration Application",
  "procedure.procedure6.text":
    "Once the required documentation is submitted, your S.A.S. registration application will be formally entered.",
  "procedure.procedure7.title": "Obtaining CUIT",
  "procedure.procedure7.text":
    "Once the registration application is approved by the Public Registry, the CUIT (Tax Identification Code) for your S.A.S. will be automatically generated.",
  "procedure.procedure8.title": "Publication in Official Gazette",
  "procedure.procedure8.text":
    "In compliance with the duty of information, the most relevant data of your S.A.S. will be published for one day in the Official Gazette.",
  "procedure.procedure9.title": "Registration Resolution",
  "procedure.procedure9.text":
    "Upon completion of the procedure, the Public Registry will issue the respective Registration Resolution and assign the registration number to your S.A.S. It will be sent to you as a digitally signed .pdf document.",
  "procedure.procedureReady.title": "Ready!",
  "procedure.procedureReady.text":
    "Your S.A.S. is fully operational and ready for you to start any type of business or operation.",
  "procedure.completeTheFormTitle": "Complete the form",
  "procedure.completeTheFormText":
    "Just take a few minutes to fill in some details, and we will take care of preparing all the necessary documents to establish your company and guide you through the entire process.",

  // Costs
  "costs.costs": "Costs",
  "costs.whatAreTheCostsInvolved": "What are the costs involved?",
  "costs.expenses": "Expenses",
  "costs.expenses.ipj.1": "IPJ Service Fee: {pesos}.",
  "costs.expenses.ipj.2": "Valid until {validity}.",
  "costs.expenses.signatureCertification.1": "Signature Certifications.",
  "costs.expenses.signatureCertification.2":
    "Please consult fees with a notary professional.",
  "costs.expenses.deposit.1": "25% Share Capital Deposit.",
  "costs.expenses.deposit.2":
    "It will be refunded to a bank account within 72 business hours after the company is incorporated.",
  "costs.professionalFees": "Professional Fees",
  "costs.professionalFees.1": "Our professional fees amount to {pesos}.",
  "costs.professionalFees.2": "Valid until {validity}.",
  "costs.paymentMethod": "Payment Method",
  "costs.paymentMethod.1":
    "The total expenses and a fifty percent (50%) advance payment of professional fees must be paid when entrusting the procedure, and the remaining fifty percent (50%) upon obtaining the Registration Resolution and Matriculation of your S.A.S.",
  "costs.disclaimer":
    "* The expenses related to the transfer of twenty-five percent (25%) of the share capital can be directly handled by the user or you can send the money to our team, and we will take care of it. Later, the refund will be made to an account held by the shareholders.",

  // FAQ
  "faq.faq": "Frequently asked questions",
  "faq.question1":
    "I'm not from Córdoba, can I still establish an S.A.S. in Córdoba?",
  "faq.answer1":
    "Yes. The only requirement is that the registered office of your S.A.S. must be located within the province of Córdoba. If you don't have an address to establish it, please contact us so we can assist you.",
  "faq.question2":
    "What are the differences between an S.A.S., an S.A., and an S.R.L.?",

  "faq.answer2":
    "While all three types of companies are fully useful and effective for conducting business or activities, the S.A.S. allows for greater flexibility and simplicity in its incorporation and subsequent development. If you are interested in knowing in detail the main differences between each of the most commonly used company types in our country, we invite you to read the following report: ...",
  "faq.question3":
    "What types of activities or businesses can I engage in with my S.A.S.?",
  "faq.answer3":
    "The S.A.S. has the possibility to include multiple possible activities to develop. You can choose between a pre-approved multiple purpose by the regulatory authority or design a specific one for the Company. The following cannot be the purpose of an S.A.S.: a) capitalization operations, savings, or any form that requires money or values from the public with promises of future benefits; b) exploitation of public concessions or services.",
  "faq.question4":
    "Can I be a shareholder or administrator of an S.A.S. if I'm a foreigner?",
  "faq.answer4":
    "Yes. However, as this is an exceptional case, the procedure and requirements may vary from the regular process. If you are a foreigner and wish to be part of an S.A.S., we invite you to contact us for personalized assistance and to evaluate your case.",
  "faq.question5": "Can I establish a single-member S.A.S.?",
  "faq.answer5":
    "Yes. An S.A.S. can be established with a single person as a partner. However, you will need a second person to fill the positions of the Company's management body, and this person does not need to be a partner of the Company.",
  "faq.question6":
    "What is the minimum required capital to establish an S.A.S.?",
  "faq.answer6":
    "The share capital cannot be less than the equivalent of two (2) times the minimum vital and mobile salary established at the time of its incorporation.",
  "faq.question7": "How do Digital Books work?",
  "faq.answer7":
    "S.A.S. companies are required to keep Digital Books where all the acts of the Company must be recorded. Access is done through a Portal provided by the regulatory authority, where all relevant minutes and documents can be viewed and uploaded, and they remain unaltered in a cloud accessible only to the Company's administrators and/or those designated by them for this purpose. If you need further advice regarding digital books, please contact us so we can assist you.",
  "faq.question8":
    "What economic activities are covered by the pre-approved purpose by DIPJ?",
  "faq.answer8":
    "The pre-approved purpose by DIPJ includes the following activities:",
  "faq.answer8.1":
    "Construction of all types of works, public or private, buildings, residences, commercial premises, and industrial plants; performing renovations, remodels, installations, masonry work, and/or any construction-related tasks.",
  "faq.answer8.2":
    "National or international transportation of general cargo, whether by land, air, or sea, using own or third-party means of transportation, and performing all related logistics activities.",
  "faq.answer8.3":
    "Buying, selling, swapping, exploiting, leasing, and managing real estate properties, both urban and rural, and conducting operations related to horizontal property.",
  "faq.answer8.4":
    "Engaging in all kinds of financial operations through means authorized by current legislation. Operations covered by the Financial Entities Law are excluded.",
  "faq.answer8.5":
    "Directly operating or through third parties in rural establishments, livestock farming, agriculture, poultry farming, fruit growing, viticulture, forestry, livestock breeding, sale and crossbreeding of cattle, dairy farming, cultivation, purchasing, selling, and storage of grains.",
  "faq.answer8.6":
    "Manufacturing, producing, transforming, and marketing all types of food products and by-products, selling all types of beverages, operating catering services, gastronomic concessions, bars, restaurants, cafeterias, and organizing and providing logistical support for social events.",
  "faq.answer8.7":
    "Creating, producing, developing, transforming, repairing, implementing, providing technical service, consultancy, commercializing, distributing, importing, and exporting software, computer, electrical, and electronic equipment.",
  "faq.answer8.8":
    "Producing, organizing, and operating public and private shows, theatrical, musical, choreographic performances, parades, exhibitions, fairs, music concerts, recitals, and social events.",
  "faq.answer8.9":
    "Operating travel and tourism agencies, including booking and selling land, air, and sea tickets, both national and international; organizing, booking, and selling excursions, hotel reservations, arranging and selling charters and transfers for groups within and outside the country.",
  "faq.answer8.10":
    "Organizing, administering, managing, and operating medical care centers, providing comprehensive and integrated medical care, clinical, therapeutic, and surgical services, with or without hospitalization, and other health-related activities and medical services.",
  "faq.answer8.11":
    "Establishing, installing, and operating publishing and graphic companies on any medium.",
  "faq.answer8.12":
    "Installation and operation of establishments dedicated to the industrialization, manufacturing, and production of raw materials, products, and by-products directly related to its corporate purpose.",
  "faq.answer8.13": "Import and export of goods and services.",
  "faq.answer8.14":
    "Acting as a grantor, trustee, beneficiary, fiduciary, on its own behalf or on behalf of third parties, and/or associated with third parties, in all types of ventures.",
  "faq.question9": "Is it possible to draft my own statutory provisions?",
  "faq.answer9":
    "Yes. If you wish to have articles of incorporation tailored to specific needs, please contact us to assess the matter and inform you of the steps to follow.",

  // Contact
  "contact.contact": "Contact",
  "contact.name": "Name",
  "contact.lastName": "Last name",
  "contact.company": "Company",
  "contact.contactPhone": "Contact phone",
  "contact.message": "Message",
  "contact.sendMyData": "Send my data",
  "contact.invalidEmail": "Invalid e-mail",
  "contact.emailSent": "Your contact information was successfully sent!",

  // Footer
  "footer.text":
    "QuieroMiSAS provides its services as an intermediary for legal procedures before the Dirección General de Inspección de Personas Jurídicas, for which it contracts third-party services located in the country. It is not part of any consortium, government entities, state entities, or any other entities that grant registration rights for companies in the province of Córdoba. It also does not have any alliances, commercial partnerships, or direct relationships with banks in the country. QuieroMiSAS does not provide, nor intends to provide, any type of tax or legal service, advice, or guidance to its clients and users. Any information published in this regard corresponds solely to public information.",
  "footer.privacyPolicy": "Privacy policy",
  "footer.privacyPolicy.title1": "QuieroMiSAS Privacy Policy",
  "footer.privacyPolicy.text1":
    "This Privacy Policy describes how QuieroMiSAS ('we', 'our', or 'ours') collects, uses, and protects the personal information you provide when using our website. By using our site, you agree to the practices outlined in this policy.",
  "footer.privacyPolicy.title2": "Information We Collect",
  "footer.privacyPolicy.text2.1":
    "We may collect the following personal information when you use our website:",
  "footer.privacyPolicy.text2.2":
    "Contact information, such as name, email address, and phone number.",
  "footer.privacyPolicy.text2.3":
    "Demographic information, such as country, province, and city.",
  "footer.privacyPolicy.text2.4":
    "Information about your business or company, such as company name, company type, and details related to company formation.",
  "footer.privacyPolicy.text2.5":
    "Payment information, if you engage in transactions through our website.",
  "footer.privacyPolicy.text2.6":
    "Login information, if you create an account on our website.",
  "footer.privacyPolicy.title3": "How We Use Your Information",
  "footer.privacyPolicy.text3.1":
    "We use the collected information for the following purposes:",
  "footer.privacyPolicy.text3.2":
    "Process and complete your requests for company formation and other related services.",
  "footer.privacyPolicy.text3.3":
    "Communicate with you regarding your request and provide relevant information.",
  "footer.privacyPolicy.text3.4":
    "Improve our services and personalize your experience on our website.",
  "footer.privacyPolicy.text3.5":
    "Administer and maintain your account, if created.",
  "footer.privacyPolicy.text3.6":
    "Comply with our legal and regulatory obligations.",
  "footer.privacyPolicy.title4": "Disclosure of Information",
  "footer.privacyPolicy.text4.1":
    "We do not sell or rent your personal information to third parties. We may share your information with:",
  "footer.privacyPolicy.text4.2":
    "Service providers who assist us in delivering services and operating our website.",
  "footer.privacyPolicy.text4.3":
    "Legal or governmental authorities in response to a valid court order or other legal request.",
  "footer.privacyPolicy.text4.4":
    "In the event of a merger, acquisition, or asset sale, where your information might be transferred as part of such transaction.",
  "footer.privacyPolicy.title5": "Information Security",
  "footer.privacyPolicy.text5":
    "We take reasonable measures to protect your personal information against unauthorized access, misuse, or disclosure. However, no Internet transmission or electronic storage is completely secure.",
  "footer.privacyPolicy.title6": "Your Rights",
  "footer.privacyPolicy.text6":
    "You have rights over your personal information, including the right to access, correct, update, or delete the information we have about you. If you wish to exercise any of these rights, please contact us at contacto@quieromisas.com.",
  "footer.privacyPolicy.title7": "Changes to our Privacy Policy",
  "footer.privacyPolicy.text7.1":
    "We may update our Privacy Policy periodically to reflect changes in our practices and services. Any significant changes will be reflected on this page.",
  "footer.privacyPolicy.text7.2":
    "If you have any questions or concerns about our Privacy Policy, feel free to contact us at contacto@quieromisas.com.",
  "footer.privacyPolicy.text7.3":
    "By using our website, you agree to the practices outlined in this Privacy Policy.",

  "footer.cookiesPolicy.title1": "QuieroMiSAS Cookie Policy",
  "footer.cookiesPolicy": "Cookies policy",
  "footer.cookiesPolicy.text1":
    "This Cookie Policy explains how QuieroMiSAS ('we', 'our', or 'ours') uses cookies and similar technologies on our website. By using our website, you consent to the use of cookies in accordance with this policy. If you do not agree with the use of cookies, we recommend adjusting your browser settings or discontinuing the use of our website.",
  "footer.cookiesPolicy.title2": "What are cookies?",
  "footer.cookiesPolicy.text2":
    "Cookies are small text files that are stored on your device (computer, tablet, mobile phone, etc.) when you visit our website. These cookies allow the website to remember your actions and preferences (such as login, language, font size, and other display preferences) over a period of time, so you don't have to enter them again each time you visit the site or navigate from one page to another.",
  "footer.cookiesPolicy.title3":
    "What types of cookies do we use and for what purposes?",
  "footer.cookiesPolicy.subtitle3.1": "Essential Cookies",
  "footer.cookiesPolicy.text3.1":
    "These cookies are necessary for the website to function properly. They do not collect personal information and are usually set in response to actions you take on the site, such as completing forms or setting your privacy preferences.",
  "footer.cookiesPolicy.subtitle3.2": "Performance Cookies",
  "footer.cookiesPolicy.text3.2":
    "These cookies allow us to analyze how visitors use our website and improve its performance. They collect information such as the most frequently visited pages and any error messages that may appear. All collected information is anonymous and is used solely to enhance the site's performance.",
  "footer.cookiesPolicy.subtitle3.3": "Functionality Cookies",
  "footer.cookiesPolicy.text3.3":
    "These cookies enable the website to remember choices you have made (such as your username, language, or region) and provide enhanced, more personalized features. The information collected by these cookies is anonymous and does not track your browsing activity on other websites.",
  "footer.cookiesPolicy.subtitle3.4": "Advertising Cookies",
  "footer.cookiesPolicy.text3.4":
    "These cookies are used to display ads relevant to you and your interests. They may also be used to limit the number of times you see an ad and help measure the effectiveness of an advertising campaign. These cookies are typically placed by third-party advertising networks.",
  "footer.cookiesPolicy.title4": "How to control cookies",
  "footer.cookiesPolicy.text4.1":
    "You can control and manage cookies in your browser. Please note that disabling essential cookies may impact the functionality and browsing experience on our website.",
  "footer.cookiesPolicy.text4.2":
    "If you wish to block or delete cookies, refer to your browser's help section for instructions on how to do so. Please note that disabling certain cookies may result in some features or functions of the website not being available to you.",
  "footer.cookiesPolicy.title5": "Changes to our Cookie Policy",
  "footer.cookiesPolicy.text5.1":
    "We may update our Cookie Policy from time to time to reflect changes in our practices and services. Any significant changes will be reflected on this page.",
  "footer.cookiesPolicy.text5.2":
    "If you have any questions or concerns about our Cookie Policy, feel free to contact us at contacto@quieromisas.com.",
  "footer.cookiesPolicy.text5.3":
    "By continuing to use our website, you agree to the use of cookies in accordance with this Cookie Policy.",

  // Form steps
  "formSteps.step": "Step",
  "formSteps.step1.title": "Personal data",
  "formSteps.step1.subtitle1": "Start the constitution of your Company",
  "formSteps.step1.text1":
    "We will take care of all the necessary documentation and inform you of the steps to follow. For this purpose, we request the following information to begin.",
  "formSteps.step2.title": "Company name",
  "formSteps.step2.subtitle1": "Choose the name of your company",
  "formSteps.step2.text1":
    "We kindly ask you to provide us with three alternative corporate names since if one of them is not available, we will attempt to reserve the next one.",
  "formSteps.step2.text2":
    "The chosen name will undergo a prior homonymy analysis by the regulatory authority.",
  "formSteps.step3.title": "Activity and Headquarters",
  "formSteps.step3.subtitle1":
    "Select the activity you wish to carry out with your Company",
  "formSteps.step3.text1":
    "S.A.S. companies have the option to engage in multiple activities, so you can choose the pre-approved standard purpose or entrust us to draft a customized purpose that meets your needs.",
  "formSteps.step3.subtitle2": "Choose the Headquarters Office",
  "formSteps.step3.text2":
    "It must be located within the Province of Córdoba. If you don't have an address, you can contact us for assistance.",
  "formSteps.step3.checkbox.preApprovedObject": "Pre-approved Purpose",
  "formSteps.step3.checkbox.customizedObject": "Customized Purpose",
  "formSteps.step3.activities.construction": "Construction",
  "formSteps.step3.activities.transportation": "Transportation",
  "formSteps.step3.activities.realEstate": "Real Estate",
  "formSteps.step3.activities.finance": "Financial",
  "formSteps.step3.activities.agriculture": "Agriculture",
  "formSteps.step3.activities.livestock": "Livestock",
  "formSteps.step3.activities.foodRelated": "Food-related",
  "formSteps.step3.activities.services": "Services",
  "formSteps.step3.activities.events": "Events",
  "formSteps.step3.activities.travelAndTourism": "Travel and Tourism",
  "formSteps.step3.activities.medical": "Medical",
  "formSteps.step3.activities.publishingAndGraphics": "Publishing and Graphics",
  "formSteps.step3.activities.industrial": "Industrial",
  "formSteps.step3.activities.importExport": "Import and Export",
  "formSteps.step3.activities.trusts": "Trusts",
  "formSteps.step4.title": "Share capital",
  "formSteps.step4.subtitle1": "Let us know the desired Share capital",
  "formSteps.step4.text1":
    "S.A.S. companies must have a share capital that is not less than two (2) times the Minimum Vital and Mobile Salary (SMVM).",
  "formSteps.step4.currentSMVM": "Current SMVM",
  "formSteps.step4.SMVMfromDate": "SMVM from day {day}",
  "formSteps.step4.minimumShareCapitalWarning":
    "The minimum share capital is $ {number}",
  "formSteps.step4.lengthCbuWarning": "Must be exactly 22 digits",
  "formSteps.step4.onlyDigitsCbuWarning": "Must be only digits",
  "formSteps.step4.validCBU": "Invalid CBU",
  "formSteps.step4.text2":
    "For the purpose of its incorporation, a deposit of twenty-five percent (25%) of the chosen value is required in a bank account that will be specially opened for this purpose.",
  "formSteps.step4.text3":
    "We will provide the necessary information for making the transfer in due course.",
  "formSteps.step4.text4":
    "Later, once the company is incorporated, the deposited amount will be refunded to a bank account of the Administrator or the Shareholders of the Company, so we kindly ask you to provide us with two (2) CBU numbers where the money can be returned.",
  "formSteps.step4.text5":
    "* Virtual bank accounts such as Brubank, MercadoPago, etc., are not allowed.",
  "formSteps.step4.cbuWarning": "The Primary CBU must belong to the Main Administrator. The Secondary CBU should correspond to another account of the Main Administrator or another Administrator. The refund accounts entered CANNOT be associated with a Clave Virtual Uniforme (CVU). They must correspond to a Clave Bancaria Uniforme (CBU).",
  "formSteps.step5.title": "Shareholders",
  "formSteps.step5.subtitle1": "Let's determine who the shareholders will be",
  "formSteps.step5.numberOfShareholders": "Number of shareholders",
  "formSteps.step5.text1":
    "S.A.S. companies can be single-member or have multiple shareholders, each holding a certain number of shares and rights in the Company. To proceed, we kindly ask you to provide us with the following information:",
  "formSteps.step5.alert":
    "It is important that the individuals designated as shareholders have their tax address declared before AFIP and have their CUIT enabled. Otherwise, this situation must be regularized to proceed with the procedure.",
  "formSteps.step5.capitalContributionRange": "Must be a number between 0 and 100",
  "formSteps.step5.capitalContributionTotalMessage": "The sum of the capital contributions from all shareholders must equal 100%",
  "formSteps.step5.maritalStatus.single": "Single",
  "formSteps.step5.maritalStatus.married": "Married",
  "formSteps.step5.maritalStatus.widowed": "Widowed",
  "formSteps.step5.maritalStatus.divorced": "Divorced",
  "formSteps.step6.title": "Management",
  "formSteps.step6.subtitle1": "Let's determine who the administrators will be",
  "formSteps.step6.numberOfAdministrators": "Number of administrators",
  "formSteps.step6.text1":
    "At least one Main Administrator and one Substitute Administrator must be appointed. They do not need to be shareholders of the Company.",
  "formSteps.step6.mainAdministrator": "Main administrator",
  "formSteps.step6.substituteAdministrator": "Substitute administrator",
  "formSteps.step6.alert":
    "It is important that the individuals designated as administrators have their tax address declared before AFIP and have their CUIT enabled. Otherwise, this situation must be regularized to proceed with the procedure.",
  "formSteps.step7.title": "Accounting",
  "formSteps.step7.subtitle1": "Financial Year End",
  "formSteps.step7.text1":
    "Companies must prepare annual financial statements that reflect the financial position of the Company.",
  "formSteps.step7.text2":
    "The date chosen as the financial year end is at your discretion, but the default is often set as December 31st of each year.",
  "formSteps.step7.closingFiscalDate": "Closing fiscal date",
  "formSteps.step7.closingFiscalDate.december31": "December 31st",
  "formSteps.finalStep.subtitle1": "Ready!",
  "formSteps.finalStep.text1":
    "We will be contacting you via email or WhatsApp within the next 48 business hours to coordinate the payment and proceed with the incorporation of your company.",
};

export default messages;
