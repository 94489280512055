import { FC, lazy } from "react";
import { Route, Routes as RoutesDom } from "react-router-dom";

import HomePage from "../../pages/home";

const TiposSocietariosPage = lazy(() => import("../../pages/home/otras/TiposSocietariosPage"));
const IWantMySasFormPage = lazy(() => import("../../pages/quieroMiSasForm"));

const Routes: FC = () => (
  <RoutesDom>
    <Route path="/" element={<HomePage />} />
    <Route path="/tiposSocietarios" element={<TiposSocietariosPage />} />
    <Route path="/quieroMiSas" element={<IWantMySasFormPage />} />
  </RoutesDom>
);

export default Routes;
