import { FC, ReactNode } from "react";
import { useIntl } from "react-intl";

import { makeStyles } from "tss-react/mui";
import {
  Box,
  BoxProps,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import logo from "../assets/images/quiero-mi-sas-logo.png";

interface MakeStylesProps {
  isSmOrDown: boolean;
  isLgOrDown: boolean;
}
const useStyles = makeStyles<MakeStylesProps>()(
  (theme, { isSmOrDown, isLgOrDown }) => ({
    mainBox: {
      height: "100%",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "row",
    },
    leftBox: {
      backgroundColor: theme.palette.background.default,
    },
    rightBox: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    logoBox: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(8),
      cursor: "pointer",
    },
    stepCirclesContainer: {
      display: "flex",
      flexDirection: "row",
      gap: theme.spacing(2),
    },
    stepCircleWrapper: {
      width: isSmOrDown ? "40px" : "58px",
      height: isSmOrDown ? "40px" : "58px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#8A8A8A",
    },
    stepIcon: {
      width: isSmOrDown ? "40px" : "58px",
      height: isSmOrDown ? "40px" : "58px",
    },
    stepCircleCompleted: {
      backgroundColor: theme.palette.background.default,
    },
    stepCircleCurrent: {
      backgroundColor: theme.palette.divider,
    },
    stepTitleBox: {
      display: "flex",
      flexDirection: isSmOrDown ? "row" : "column",
      gap: isSmOrDown ? theme.spacing(2) : undefined,
      alignItems: "flex-start",
    },
    stepTitleStepText: {
      fontSize: isSmOrDown ? theme.spacing(2.75) : theme.spacing(3.75),
      lineHeight: isSmOrDown ? theme.spacing(3.5) : theme.spacing(4.5),
    },
    stepTitleText: {
      fontSize: isSmOrDown ? theme.spacing(2.75) : theme.spacing(3.75),
      fontWeight: 700,
      lineHeight: isSmOrDown ? theme.spacing(3.5) : theme.spacing(4.5),
    },
    stepTitleContainer: {
      display: "flex",
      flexDirection: isSmOrDown ? "column" : "row",
      gap: isSmOrDown ? theme.spacing(2) : theme.spacing(6),
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    stepTitleWrapper: {
      display: "flex",
      alignItems: "center",
    },
    stepIconWrapper: {
      width: "100%",
      height: "100%",
    },
    rightBoxContainer: {
      display: "flex",
      flexDirection: "column",
      gap: isSmOrDown ? theme.spacing(2) : theme.spacing(6),
      alignItems: "center",
      width: "100%",
      marginTop: isLgOrDown ? theme.spacing(3) : theme.spacing(6),
      marginBottom: isLgOrDown ? theme.spacing(3) : theme.spacing(6),
    },
    formStepContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      border: `2px solid #C0C0C0`,
      borderRadius: theme.spacing(3),
      width: isSmOrDown ? "98%" : "70%",
    },
    formWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      height: "100%",
      padding: isSmOrDown ? theme.spacing(3) : theme.spacing(6),
    },
  })
);

interface StepWrapperProps extends BoxProps {
  stepNumber: number;
  stepTitle?: string;
  stepIcon?: JSX.Element;
  totalSteps: number;
  children: ReactNode;
}

export const StepWrapper: FC<StepWrapperProps> = ({
  stepNumber,
  stepTitle,
  stepIcon,
  totalSteps,
  children,
  ...props
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("md"));
  const isLgOrDown = useMediaQuery(theme.breakpoints.down("xl"));
  const { classes } = useStyles({ isSmOrDown, isLgOrDown });

  const CircleSteps = () => (
    <Box className={classes.stepTitleWrapper}>
      <Box className={classes.stepCirclesContainer}>
        {Array.from({ length: totalSteps }, (_, index) => index + 1).map(
          (value) => (
            <Box
              key={value}
              className={`${classes.stepCircleWrapper} ${
                value < stepNumber
                  ? classes.stepCircleCompleted
                  : value === stepNumber
                  ? classes.stepCircleCurrent
                  : ""
              }`}
            >
              {value === stepNumber && stepIcon && stepIcon}
            </Box>
          )
        )}
      </Box>
    </Box>
  );
  const StepTitle = () => (
    <Box>
      <Box className={classes.stepTitleBox}>
        {stepNumber <= totalSteps && (
          <>
            <Typography className={classes.stepTitleStepText}>
              {`${intl.formatMessage({
                id: "formSteps.step",
              })} ${stepNumber}/${totalSteps}`}
            </Typography>
            <Typography className={classes.stepTitleText}>
              {stepTitle}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );

  return (
    <Grid container className={classes.mainBox}>
      {!isSmOrDown && (
        <Grid item xs={0} md={3} className={classes.leftBox}>
          <Box className={classes.logoBox} component={Link} href="/">
            <img src={logo} alt={"QuieroMiSAS logo"} width="80%" height="auto" />
          </Box>
        </Grid>
      )}
      <Grid item md={9} xs={12} className={classes.rightBox}>
        <Box className={classes.rightBoxContainer}>
          <Box className={classes.stepTitleContainer}>
            {CircleSteps()}
            {StepTitle()}
          </Box>
          <Box className={classes.formStepContainer}>
            <Box className={classes.formWrapper}>{children}</Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
