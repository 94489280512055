import { useIntl } from "react-intl";

import { makeStyles } from "tss-react/mui";
import { Typography, Box } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    "& ul": {
      margin: 0,
    },
  },
  dialogTitle: {
    fontSize: theme.spacing(2),
    fontWeight: 700,
    textDecoration: "underline",
  },
  dialogSubtitle: {
    fontSize: theme.spacing(2),
    fontWeight: 400,
    textDecoration: "underline",
  },
  dialogText: {
    fontSize: theme.spacing(2),
    fontWeight: 400,
  },
  brandContainer: {
    marginTop: theme.spacing(3),
  },
  brandName: {
    fontSize: theme.spacing(2),
    fontWeight: 700,
  },
}));

export const CookiesPolicyContent = () => {
  const intl = useIntl();
  const { classes } = useStyles();

  return (
    <Box className={classes.dialogContainer}>
      <Typography className={classes.dialogTitle}>
        {intl.formatMessage({ id: "footer.cookiesPolicy.title1" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.cookiesPolicy.text1" })}
      </Typography>
      <Typography className={classes.dialogTitle}>
        {intl.formatMessage({ id: "footer.cookiesPolicy.title2" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.cookiesPolicy.text2" })}
      </Typography>
      <Typography className={classes.dialogTitle}>
        {intl.formatMessage({ id: "footer.cookiesPolicy.title3" })}
      </Typography>
      <Box>
        <Typography className={classes.dialogSubtitle}>
          {intl.formatMessage({
            id: "footer.cookiesPolicy.subtitle3.1",
          })}
        </Typography>
        <Typography className={classes.dialogText}>
          {intl.formatMessage({ id: "footer.cookiesPolicy.text3.1" })}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.dialogSubtitle}>
          {intl.formatMessage({
            id: "footer.cookiesPolicy.subtitle3.2",
          })}
        </Typography>
        <Typography className={classes.dialogText}>
          {intl.formatMessage({ id: "footer.cookiesPolicy.text3.2" })}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.dialogSubtitle}>
          {intl.formatMessage({
            id: "footer.cookiesPolicy.subtitle3.3",
          })}
        </Typography>
        <Typography className={classes.dialogText}>
          {intl.formatMessage({ id: "footer.cookiesPolicy.text3.3" })}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.dialogSubtitle}>
          {intl.formatMessage({
            id: "footer.cookiesPolicy.subtitle3.4",
          })}
        </Typography>
        <Typography className={classes.dialogText}>
          {intl.formatMessage({ id: "footer.cookiesPolicy.text3.4" })}
        </Typography>
      </Box>
      <Typography className={classes.dialogTitle}>
        {intl.formatMessage({ id: "footer.cookiesPolicy.title4" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.cookiesPolicy.text4.1" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.cookiesPolicy.text4.2" })}
      </Typography>
      <Typography className={classes.dialogTitle}>
        {intl.formatMessage({ id: "footer.cookiesPolicy.title5" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.cookiesPolicy.text5.1" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.cookiesPolicy.text5.2" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.cookiesPolicy.text5.3" })}
      </Typography>
      <Box className={classes.brandContainer}>
        <Typography className={classes.brandName}>QuieroMiSAS</Typography>
        <Typography className={classes.brandName}>
          www.quieromisas.com
        </Typography>
      </Box>
    </Box>
  );
};

export const PrivacyPolicyContent = () => {
  const intl = useIntl();
  const { classes } = useStyles();

  return (
    <Box className={classes.dialogContainer}>
      <Typography className={classes.dialogTitle}>
        {intl.formatMessage({ id: "footer.privacyPolicy.title1" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.privacyPolicy.text1" })}
      </Typography>
      <Typography className={classes.dialogTitle}>
        {intl.formatMessage({ id: "footer.privacyPolicy.title2" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.privacyPolicy.text2.1" })}
      </Typography>
      <ul>
        {Array.from({ length: 5 }, (_, index) => index + 2).map((number) => (
          <li key={number}>
            <Typography className={classes.dialogText}>
              {intl.formatMessage({
                id: `footer.privacyPolicy.text2.${number}`,
              })}
            </Typography>
          </li>
        ))}
      </ul>
      <Typography className={classes.dialogTitle}>
        {intl.formatMessage({ id: "footer.privacyPolicy.title3" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.privacyPolicy.text3.1" })}
      </Typography>
      <ul>
        {Array.from({ length: 5 }, (_, index) => index + 2).map((number) => (
          <li key={number}>
            <Typography className={classes.dialogText}>
              {intl.formatMessage({
                id: `footer.privacyPolicy.text3.${number}`,
              })}
            </Typography>
          </li>
        ))}
      </ul>
      <Typography className={classes.dialogTitle}>
        {intl.formatMessage({ id: "footer.privacyPolicy.title4" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.privacyPolicy.text4.1" })}
      </Typography>
      <ul>
        {Array.from({ length: 3 }, (_, index) => index + 2).map((number) => (
          <li key={number}>
            <Typography className={classes.dialogText}>
              {intl.formatMessage({
                id: `footer.privacyPolicy.text4.${number}`,
              })}
            </Typography>
          </li>
        ))}
      </ul>
      <Typography className={classes.dialogTitle}>
        {intl.formatMessage({ id: "footer.privacyPolicy.title5" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.privacyPolicy.text5" })}
      </Typography>
      <Typography className={classes.dialogTitle}>
        {intl.formatMessage({ id: "footer.privacyPolicy.title6" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.privacyPolicy.text6" })}
      </Typography>
      <Typography className={classes.dialogTitle}>
        {intl.formatMessage({ id: "footer.privacyPolicy.title7" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.privacyPolicy.text7.1" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.privacyPolicy.text7.2" })}
      </Typography>
      <Typography className={classes.dialogText}>
        {intl.formatMessage({ id: "footer.privacyPolicy.text7.3" })}
      </Typography>
      <Box className={classes.brandContainer}>
        <Typography className={classes.brandName}>QuieroMiSAS</Typography>
        <Typography className={classes.brandName}>
          www.quieromisas.com
        </Typography>
      </Box>
    </Box>
  );
};
