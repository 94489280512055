import { useState } from "react";
import { useIntl } from "react-intl";

import { makeStyles } from "tss-react/mui";
import {
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Container,
  IconButton,
  Divider,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { Dialog } from "./Dialog";
import { LanguageSelector } from "./LanguageSelector";
import logo from "../assets/images/crea-tu-sas-logo.png";
import mwLogo from "../assets/images/mw-logo.png";
import {
  CookiesPolicyContent,
  PrivacyPolicyContent,
} from "./PrivacyCookiesPolicies";

interface MakeStylesProps {
  isSmOrDown: boolean;
}
const useStyles = makeStyles<MakeStylesProps>()((theme, { isSmOrDown }) => ({
  wrapper: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    padding: theme.spacing(2),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
  },
  topContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    color: "#FFFFFF",
    fontSize: theme.spacing(2),
  },
  footerText: {
    textAlign: isSmOrDown ? "justify" : undefined,
  },
  logoBox: {
    height: "48px",
  },
  logo: {
    height: "100%",
    width: "100%",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
  },
  iconButton: {
    color: theme.palette.background.default,
    backgroundColor: "#FFFFFF",
    height: "48px",
    width: "48px",
    "&:hover": {
      backgroundColor: theme.palette.divider,
      color: "#FFFFFF",
    },
  },
  bottomContent: {
    display: "flex",
    flexDirection: isSmOrDown ? "column" : "row",
    justifyContent: isSmOrDown ? "center" : "flex-end",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  languageBox: {
    display: "flex",
    justifyContent: isSmOrDown ? "center" : "flex-start",
  },
  languageBottomContentBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: isSmOrDown ? "center" : "space-between",
    alignItems: "center",
  },
  link: {
    textTransform: "uppercase",
    cursor: "pointer",
  },
  divider: {
    backgroundColor: theme.palette.divider,
    width: "2px",
    height: theme.spacing(2),
  },
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  dialogTitle: {
    fontSize: theme.spacing(2),
    fontWeight: 700,
    textDecoration: "underline",
  },
  dialogSubtitle: {
    fontSize: theme.spacing(2),
    fontWeight: 400,
    textDecoration: "underline",
  },
  dialogText: {
    fontSize: theme.spacing(2),
    fontWeight: 400,
  },
  brandContainer: {
    marginTop: theme.spacing(3),
  },
  brandName: {
    fontSize: theme.spacing(2),
    fontWeight: 700,
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  logoText: {
    fontSize: theme.spacing(1.5),
  },
  poweredByWithLogoMobileContainer: {
    alignSelf: "center",
  },
}));

const PoweredByWithLogo = () => {
  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("md"));
  const { classes } = useStyles({ isSmOrDown });

  return (
    <Box
      className={`${classes.logoContainer} ${
        isSmOrDown ? classes.poweredByWithLogoMobileContainer : ""
      }`}
    >
      <Typography className={`${classes.text} ${classes.logoText}`}>
        Powered by:
      </Typography>
      <a href="https://martinezwehbe.com.ar/" target="blank">
        <img
          src={mwLogo}
          alt={"Martinez, Wehbe y Asociados logo"}
          width="auto"
          height="48px"
        />
      </a>
    </Box>
  );
};

export const Footer = () => {
  const intl = useIntl();
  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("md"));
  const { classes } = useStyles({ isSmOrDown });
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [openCookiesPolicy, setOpenCookiesPolicy] = useState(false);

  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="lg">
        <Box className={classes.content}>
          <Box className={classes.topContent}>
            <Box className={classes.logoContainer}>
              <img
                src={logo}
                alt={"QuieroMiSAS logo"}
                width="auto"
                height="48px"
              />
              {!isSmOrDown && <PoweredByWithLogo />}
            </Box>
            <Box className={classes.buttonsContainer}>
              <IconButton
                href={process.env.REACT_APP_WHATSAPP_LINK || ""}
                target="_blank"
                className={classes.iconButton}
              >
                <WhatsAppIcon />
              </IconButton>
              <IconButton
                href={process.env.REACT_APP_INSTAGRAM_LINK || ""}
                target="_blank"
                className={classes.iconButton}
              >
                <InstagramIcon />
              </IconButton>
            </Box>
          </Box>
          {isSmOrDown && <PoweredByWithLogo />}
          <Typography className={`${classes.text} ${classes.footerText}`}>
            {intl.formatMessage({ id: "footer.text" })}
          </Typography>
          <Box className={classes.languageBottomContentBox}>
            {!isSmOrDown && (
              <Box className={classes.languageBox}>
                <LanguageSelector />
              </Box>
            )}
            <Box className={classes.bottomContent}>
              <Box onClick={() => setOpenPrivacyPolicy(true)}>
                <Typography className={`${classes.text} ${classes.link}`}>
                  {intl.formatMessage({ id: "footer.privacyPolicy" })}
                </Typography>
              </Box>
              {!isSmOrDown && <Divider className={classes.divider} />}
              <Box onClick={() => setOpenCookiesPolicy(true)}>
                <Typography className={`${classes.text} ${classes.link}`}>
                  {intl.formatMessage({ id: "footer.cookiesPolicy" })}
                </Typography>
              </Box>
            </Box>
          </Box>
          {isSmOrDown && (
            <Box className={classes.languageBox}>
              <LanguageSelector />
            </Box>
          )}
        </Box>
      </Container>
      {openPrivacyPolicy && (
        <Dialog
          open={openPrivacyPolicy}
          setOpen={setOpenPrivacyPolicy}
          title={intl.formatMessage({
            id: "footer.privacyPolicy",
          })}
          content={<PrivacyPolicyContent />}
        />
      )}
      {openCookiesPolicy && (
        <Dialog
          open={openCookiesPolicy}
          setOpen={setOpenCookiesPolicy}
          title={intl.formatMessage({
            id: "footer.cookiesPolicy",
          })}
          content={<CookiesPolicyContent />}
        />
      )}
    </Box>
  );
};
