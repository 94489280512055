import { FC, ReactNode } from "react";

import { makeStyles } from "tss-react/mui";
import { Box, BoxProps, ButtonBase, Typography } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(6),
    "&:hover": {
      backgroundColor: theme.palette.divider,
    },
  },
  textWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    flexGrow: 1,
  },
  text: {
    fontSize: theme.spacing(3.5),
    fontWeight: 700,
    color: "#FFFFFF",
  },
  selectedText: {
    color: "#000000",
  },
  selected: {
    backgroundColor: "#C0C0C0",
  },
}));

interface BenefitBoxProps extends BoxProps {
  icon: string;
  selected: boolean;
  iconAlt?: string;
  children: ReactNode;
}

export const BenefitBox: FC<BenefitBoxProps> = ({
  icon,
  selected,
  iconAlt,
  children,
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <ButtonBase
      component="div"
      className={`${classes.box} ${selected ? classes.selected : ""}`}
      {...props}
    >
      <img src={icon} alt={iconAlt || ""} width="100px" height="100px" />
      <Box className={classes.textWrapper}>
        <Typography
          className={`${classes.text} ${selected ? classes.selectedText : ""}`}
        >
          {children}
        </Typography>
      </Box>
    </ButtonBase>
  );
};
