import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, BoxProps, Container, Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(6),
  },
}));

export const PageWrapper: FC<BoxProps> = ({ children, ...props }) => {
  const { classes } = useStyles();

  return (
    <Container maxWidth="lg">
      <Box className={classes.wrapper} {...props}>
        {children}
      </Box>
    </Container>
  );
};
