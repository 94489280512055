import { FC } from "react";

import { makeStyles } from "tss-react/mui";
import { Button, ButtonProps } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: "#FFFFFF",
    width: "fit-content",
    textAlign: "center",
    "&:hover": {
      backgroundColor: theme.palette.divider,
      color: "#FFFFFF"
    },
  },
  large: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  medium: {
    fontSize: theme.spacing(2.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  small: {
    fontSize: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: "90%",
  },
}));

interface StyledButtonProps extends ButtonProps {
  customSize?: "small" | "medium" | "large";
}

export const StyledButton: FC<StyledButtonProps> = ({
  customSize = "large",
  children,
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <Button
      className={`${classes.root} ${
        customSize === "small"
          ? classes.small
          : customSize === "medium"
          ? classes.medium
          : classes.large
      }`}
      {...props}
    >
      {children}
    </Button>
  );
};
