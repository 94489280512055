import { FC } from "react";
import { makeStyles } from "tss-react/mui";

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles()((theme) => ({
  questionWrapper: {
    background: "linear-gradient(#FFFFFF, #EBEBEB)",
    "& p": {
      fontWeight: 700,
    },
  },
  answerWrapper: {
    backgroundColor: "#EBEBEB",
  },
}));

interface QuestionAnswerProps {
  question: string;
  answer: string | JSX.Element;
}

export const QuestionAnswer: FC<QuestionAnswerProps> = ({
  question,
  answer,
}) => {
  const { classes } = useStyles();

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.questionWrapper}
      >
        <Typography>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.answerWrapper}>
        {typeof answer === "string" ? (
          <Typography>{answer}</Typography>
        ) : (
          answer
        )}
      </AccordionDetails>
    </Accordion>
  );
};
