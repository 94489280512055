import { createTheme } from "@mui/material";

const lightTheme = createTheme({
  typography: {
    fontFamily: 'var(--default-font), "Roboto", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontSize: 21,
  },
  palette: {
    background: {
      default: "#db1414",
    },
    divider: "#e22024",
  },
});

export default lightTheme;
