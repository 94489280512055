import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { makeStyles } from "tss-react/mui";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import { setLanguageLocale } from "../store/applicationReducer";

interface MakeStylesProps {
  isEnglish: boolean;
}
const useStyles = makeStyles<MakeStylesProps>()((theme, { isEnglish }) => ({
  formControl: {
    minWidth: theme.spacing(20),
    "& .MuiInputBase-root": {
      fontSize: theme.spacing(2),
    },
  },
  inputLabel: {
    color: "#FFFFFF",
    fontSize: theme.spacing(2),
  },
  select: {
    color: "#FFFFFF",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF",
    },
    "& .MuiSvgIcon-root": {
      color: "#FFFFFF",
      fontSize: theme.spacing(3),
    },
    "& legend": {
      fontSize: isEnglish ? theme.spacing(3.75) : theme.spacing(2.5),
    },
  },
  menuItem: {
    fontSize: theme.spacing(2),
  },
}));

export const LanguageSelector = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const languageLocale: string = useSelector(
    (state: any) => state?.applicationReducer?.languageLocale
  );
  const [selectedLanguage, setSelectedLanguage] = useState(
    languageLocale || ""
  );
  const { classes } = useStyles({ isEnglish: languageLocale === "en-US" });

  const handleChangeLanguage = (newSelectedLanguage: string) => {
    setSelectedLanguage(newSelectedLanguage);
    dispatch(setLanguageLocale(newSelectedLanguage));
    window.location.reload();
  };

  return (
    <FormControl className={classes.formControl} size="small">
      <InputLabel id="language-select-label" className={classes.inputLabel}>
        {intl.formatMessage({ id: "common.language" })}
      </InputLabel>
      <Select
        id="language-select"
        labelId="language-select-label"
        label="Age"
        onChange={(event) => handleChangeLanguage(event.target.value)}
        value={selectedLanguage}
        className={classes.select}
      >
        <MenuItem value={"es-AR"} className={classes.menuItem}>
          {intl.formatMessage({ id: "common.spanish" })}
        </MenuItem>
        <MenuItem value={"en-US"} className={classes.menuItem}>
          {intl.formatMessage({ id: "common.english" })}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
