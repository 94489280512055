import { FC } from "react";

import { makeStyles } from "tss-react/mui";
import { Divider as MuiDivider, DividerProps } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  root: {
    borderWidth: theme.spacing(0.75),
    width: "10%",
  },
}));

export const Divider: FC<DividerProps> = ({ children, ...props }) => {
  const { classes } = useStyles();

  return <MuiDivider className={classes.root} />;
};
