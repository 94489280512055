import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { Typography, TypographyProps } from "@mui/material";

interface MakeStylesProps {
  customFontSize?: string;
}
const useStyles = makeStyles<MakeStylesProps>()(
  (theme, { customFontSize }) => ({
    typography: {
      fontSize: customFontSize ? customFontSize : theme.spacing(4.5),
      fontWeight: 700,
      color: theme.palette.background.default,
      lineHeight: 1.5,
    },
  })
);

interface SectionTitleProps extends TypographyProps {
  customFontSize?: string;
}
export const SectionTitle: FC<SectionTitleProps> = ({
  customFontSize,
  children,
  ...props
}) => {
  const { classes } = useStyles({ customFontSize });

  return (
    <Typography {...props} className={classes.typography} variant="h2">
      {children}
    </Typography>
  );
};
