import React, { useState } from "react";

interface MockRecaptchaProps {
  onChange?: () => void;
}

const MockRecaptcha: React.FC<MockRecaptchaProps> = ({ onChange }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onChange && onChange();
  };

  return (
    <div>
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        I'm not a robot
      </label>
    </div>
  );
};

export default MockRecaptcha;
