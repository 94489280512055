const DEFAULT_LOCALE = "es-AR";

export const getInitialLanguage = () => {
  const storedLanguage = localStorage.getItem("languageLocale");
  return storedLanguage || DEFAULT_LOCALE;
};

export const encode = (data: { [key: string]: any }) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export interface Attachment {
  content: string;
  filename: string;
  type: string;
}