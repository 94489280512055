import { createSlice } from "@reduxjs/toolkit";

import { getInitialLanguage } from "../common/helpers/helpers";

const applicationSlice = createSlice({
  name: "application",
  initialState: { languageLocale: getInitialLanguage() },
  reducers: {
    setLanguageLocale: (state, action) => {
      state.languageLocale = action.payload;
    },
  },
});

export const { setLanguageLocale } = applicationSlice.actions;

export default applicationSlice.reducer;
