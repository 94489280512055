import { useState } from "react";
import { useIntl } from "react-intl";
import { HashLink } from "react-router-hash-link";

import { makeStyles } from "tss-react/mui";
import {
  AppBar,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Container,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import logo from "../assets/images/crea-tu-sas-logo.png";

interface MakeStylesProps {
  isSmOrDown: boolean;
}
const useStyles = makeStyles<MakeStylesProps>()((theme, { isSmOrDown }) => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
    borderBottom: `16px solid ${theme.palette.divider}`,
  },
  toolbar: {
    alignItems: "center",
    justifyContent: isSmOrDown ? "center" : undefined,
    height: "80px",
  },
  logoBox: {
    flexGrow: 1,
    display: isSmOrDown ? "flex" : undefined,
    justifyContent: isSmOrDown ? "space-between" : undefined,
  },
  stackBox: {
    display: "flex",
    alignItems: "flex-end",
  },
  linkText: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
  link: {
    textDecoration: "none",
  },
  drawerBox: {
    backgroundColor: theme.palette.background.default,
    width: "250px",
    height: "100%",
  },
}));

interface Section {
  to: string;
  text: string;
}

export const NavBar = () => {
  const intl = useIntl();
  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("md"));
  const { classes } = useStyles({ isSmOrDown });
  const [openDrawer, setOpenDrawer] = useState(false);

  const sections: Section[] = [
    { to: "#benefits", text: intl.formatMessage({ id: "benefits.benefits" }) },
    {
      to: "#procedure",
      text: intl.formatMessage({ id: "procedure.procedure" }),
    },
    { to: "#costs", text: intl.formatMessage({ id: "costs.costs" }) },
    { to: "#faq", text: "FAQ" },
    { to: "#contact", text: intl.formatMessage({ id: "contact.contact" }) },
  ];

  const scrollWidthOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -130;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  const handleChangeDrawerState = () => {
    setOpenDrawer((prev) => !prev);
  };

  return (
    <>
      <AppBar position="sticky" className={classes.appBar}>
        <Container maxWidth="lg">
          <Toolbar className={classes.toolbar}>
            <Box className={classes.logoBox}>
              <HashLink
                to="#home"
                smooth
                scroll={(el) => scrollWidthOffset(el)}
                className={classes.link}
              >
                <img
                  src={logo}
                  alt={"QuieroMiSAS logo"}
                  width="auto"
                  height="60px"
                />
              </HashLink>
            </Box>
            {isSmOrDown && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleChangeDrawerState}
                edge="start"
                sx={{ ...(openDrawer && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
            )}
            {!isSmOrDown && (
              <Stack direction="row" spacing={2} className={classes.stackBox}>
                {sections.map((section) => (
                  <HashLink
                    to={section.to}
                    smooth
                    scroll={(el) => scrollWidthOffset(el)}
                    className={classes.link}
                    key={section.to}
                  >
                    <Typography className={classes.linkText}>
                      {section.text}
                    </Typography>
                  </HashLink>
                ))}
              </Stack>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleChangeDrawerState}
      >
        <Box
          role="presentation"
          onClick={() => setOpenDrawer(false)}
          onKeyDown={() => setOpenDrawer(false)}
          className={classes.drawerBox}
        >
          <List>
            {sections.map((section) => (
              <ListItem key={section.to} disablePadding>
                <ListItemButton>
                  <HashLink
                    to={section.to}
                    smooth
                    scroll={(el) => scrollWidthOffset(el)}
                    className={classes.link}
                    key={section.to}
                  >
                    <Typography className={classes.linkText}>
                      {section.text}
                    </Typography>
                  </HashLink>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};
